<template>
  <div class="com-game-intro">
    <div class="com-game-intro-title">
      游戏介绍
    </div>
    <div class="com-game-intro-label">
      <ul class="com-game-intro-label-list">
        <li
          v-for="(item, index) in gameInfo.sortTags"
          :key="index"
          class="com-game-intro-label-list-item"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="com-game-intro-detail">
      {{ gameInfo.intro }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComGameIntro',
  props: {
    gameInfo: {
      type: Object,
      default() {
        return {
        };
      },
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
